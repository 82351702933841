/* FONTS */

@font-face {
   font-family: "FontAwesome";
   font-weight: normal;
   font-style : normal;
      src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0");
      src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0") format("embedded-opentype"),
         url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0") format("woff2"),
         url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0") format("woff"),
         url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0") format("truetype"),
         url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular") format("svg");
}

/* General */

body {
   max-height: 100vh;
   font-family: "poppins", sans-serif;
   padding: 0;
   margin: 0;
   max-height: fit-content;
   overflow: hidden;
}

img {
   max-width: 100%;
   max-height: 100%;
}

button {
   font-size: 16px;
   border: none;
   font-family: "poppins";
}

:root {
   --sp_blue: #104470;
   --sp_pink: #fa4590;
   --sp_light-green: #8bc34a;
   --sp_dark-green: #4caf50;
   --sp_light-blue: #f1f8ff;

   --aps_green: #7AC200;
   --aps_blue: #009DFF;

   --tbc_red: #ff3a1e;
   --tbc_dark-grey: #263746;
   --tbc_light-grey: #95989a;

   --bsu_orange: #D89B28;
   --bsu_dark-blue: #21235E;
   --bsu_light-blue: #3677B2;

   --dark-grey: #595757;
}

h1 {
   font-size: clamp(2rem,3vw,1.5rem);
}

h2 {
   font-size: 30px;
}

h3 {
   font-size: 24px;
}

h4 {
   font-size: 16px;
}

table {
   border-spacing: 0;
   border-collapse: collapse;
   width: 100%;
   text-align: left;
   margin-bottom: 30px
}

th {
   border-bottom: solid 1px #bdbdbd;
   font-weight: normal;
   font-size: 18px;
   align-items: center;
   column-span: 2;
}

table tr:nth-child(2) td {
   padding: 25px 0 5px 0
}

td {
   padding: 5px 0;
   color: #757575
}

a {
   color: var(--sp_blue)
}

/* Button */
.payment-link-button {
   max-width: 100%;
   height: 55px;
   min-height: 55px;
   background-color: var(--sp_light-green);
   display: block;
   border-radius: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   color: #FFF;
   font-weight: bold;
   cursor: pointer;
   transition: 0.3s all ease-in-out;
   position: relative;
   text-decoration: none;
}

.appliance-sure .payment-link-button {
   background-color: var(--aps_green);
}

.payment-link-button:after {
   font-family: "fontAwesome";
   content: "\f061";
   margin-left: 10px;
   display: inline-block;
   position: relative;
   top: -2px;
   transition: 0.3s all ease-in-out;
}

.payment-link-button:hover:after {
   animation: jiggleArrow 0.7s forwards;
}

.payment-link-button:hover {
   box-shadow: 5px 5px var(--sp_dark-green);
   transform: translate(0px, -5px);
}

@keyframes jiggleArrow {
   0% { right: 0}
   20% { right: -10px}
   40% { right: 0}
   60% { right: -10px}
   100% { right: 0}
}

/* Layout */
.body-container {
   width: 100vw;
   height: 100vh;
   display: flex;
   grid-template-columns: 30% 70%;
}

.hero-container {
   width: calc(30vw - 50px);
   height: calc(100vh - 100px);
   overflow-y: auto;
   padding: 50px 25px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.hero-container.smart-plan {
   background-color: var(--sp_blue)
}

.hero-container.appliance-sure {
   background-color: var(--aps_blue);
}

.hero-container.the-boiler-company {
   background-color: var(--tbc_dark-grey);
}

.hero-container.boiler-safe-uk {
   background-color: var(--bsu_dark-blue);
}

.hero-container p,
.hero-container h1,
.hero-container h2,
.hero-container h3,
.hero-container h4 {
   color: #FFF;
   margin: 5px 0
}

.content-container {
   width: calc(70vw - 50px);
   height: calc(100% - 100px);
   padding: 50px 25px;
   overflow-y: auto
}

.content-container h2 {
   margin: 0 0 30px 0
}

.content-container.smart-plan h2 {
   color: var(--sp_pink);
}

.content-container.appliance-sure h2 {
   color: var(--aps_green)
}

.content-container.the-boiler-company h2 {
   color: var(--tbc_red)
}

.content-container.boiler-safe-uk h2 {
   color: var(--bsu_orange)
}

/* Hero section */
.logo-container {
   height: 80px;
   background-size: contain;
   background-repeat: no-repeat;
   margin-bottom: 40px;
}

.logo-container.smart-plan {
   background-image: url(./images/smart-plan-square.png);
}

.logo-container.appliance-sure {
   background-image: url(./images/aps-logo-white.png);
   height: 100px;
   position: relative;
   left: -10px;
}

.logo-container.the-boiler-company {
   background-image: url(./images/tbc-square.png);
}

.logo-container.boiler-safe-uk {
   background-image: url(./images/boiler-safe-square.png);
}

.payment-summary-window {
   max-width: 100%;
   height: auto;
   border: solid 1px #FFF;
   padding: 20px;
   margin: 40px 0;
   border-radius: 10px
}

.payment-summary-window h2 {
   border-bottom: solid 1px #FFF;
   padding: 0 0 15px 0;
   display: flex;
   align-items: center;
   margin-bottom: 20px;
}

.payment-summary-window h2:before {
   content: "£";
   font-size: 18px;
   font-weight: normal;
   margin-right: 5px;
}

/* Content section */

label {
   width: 100%;
   display: inline-block;
   height: 100%;
   padding: 20px 10px;
}

label:before {
   font-family: "fontAwesome";
   font-size: 25px;
   margin-right: 20px;
   position: relative;
   top: 3px
}

.pd-icon:before {
   content: "\f007";
}

.ad-icon:before {
   content: "\f041";
}

.py-icon:before {
   content: "\f154";
}

.smart-plan th {
   color: var(--sp_blue)
}

.smart-plan .pd-icon:before {
   color: var(--sp_pink)
}

.smart-plan .ad-icon:before {
   color: var(--sp_light-green)
}

.appliance-sure th {
   color: var(--dark-grey)
}

.appliance-sure .pd-icon:before {
   color: var(--aps_blue)
}

.appliance-sure .ad-icon:before {
   color: var(--aps_green)
}

.the-boiler-company th {
   color: var(--tbc_dark-grey)
}

.the-boiler-company .pd-icon:before {
   color: var(--tbc_red)
}

.the-boiler-company .ad-icon:before {
   color: var(--sp_dark-green)
}

.boiler-safe-uk th {
   color: var(--bsu_dark-blue)
}

.boiler-safe-uk .pd-icon:before {
   color: var(--bsu_light-blue)
}

.boiler-safe-uk .ad-icon:before {
   color: var(--bsu_orange)
}

.info-box {
   width: calc(100% - 20px);
   padding: 10px;
   border-radius: 10px;
   transition: 0.3s ease-in-out all;
}

.info-box .wrapper {
   display: flex;
   align-items: center;
   justify-content: space-between;
   cursor: pointer;
   padding: 0;
}

.info-box .inner-wrapper {
   display: flex;
   align-items: center;
}

.info-box h4,
.info-box p {
   margin: 10px
}

.smart-plan .info-box h4,
.smart-plan .info-box p,
.smart-plan .info-box {
   color: var(--sp_blue)
}

.appliance-sure .info-box h4,
.appliance-sure .info-box p,
.appliance-sure .info-box {
   color: var(--dark-grey)
}

.the-boiler-company .info-box h4,
.the-boiler-company .info-box p,
.the-boiler-company .info-box {
   color: var(--tbc_dark-grey)
}

.boiler-safe-uk .info-box h4,
.boiler-safe-uk .info-box p,
.boiler-safe-uk .info-box {
   color: var(--bsu_dark-blue)
}

.info-box.green {
   background-color: #e8f3dc
}

.info-box.blue {
   background-color: var(--sp_light-blue);
}

.info-box.green .inner-wrapper:before {
   font-family: "fontAwesome";
   content: "\f09d";
   font-size: 30px;
   margin: 0 10px;
}

.info-box.blue .inner-wrapper:before {
   font-family: "fontAwesome";
   content: "\f0d6";
   font-size: 30px;
   margin: 0 10px;
}

.info-box.green .wrapper:after {
   font-family: "fontAwesome";
   content: "\f106";
   font-size: 30px;
   margin: 0 20px;
   position: relative;
   display: block;
   float: right;
}

.show-card-types {
   display: none;
   gap: 30px;
   align-items: center;
   margin-top: 20px;
   padding: 30px 10px 20px 10px;
   border-top: solid 1px #757575;
}

.card-type-logo {
   max-width: 70px;
   max-height: fit-content
}

.invisible {
   position: absolute;
   opacity: 0;
}

.info-box-trigger:checked + .info-box.green .show-card-types {
   display: flex;
}

.info-box-trigger:checked + .info-box.green .wrapper:after {
   transform: rotate(180deg);
}

@media only screen and (max-width: 1300px) {
   .hero-container {
      min-width: 390px;
      width: 390px;
   }

   .content-container {
      width: auto
   }
}

@media only screen and (max-width: 920px) {
   .show-card-types {
      justify-content: space-between;
      gap: 0
   }
   
}

@media only screen and (max-width: 870px) {
   .hero-container {
      min-width: 330px
   }
}

@media only screen and (max-width: 800px) {
   body {
      overflow: auto;
   }

   .body-container {
      flex-direction: column;
      height: auto;
   }

   .hero-container {
      width: calc(100% - 50px);
      overflow: auto;
      height: fit-content;
      min-width: 0;
   }

   .payment-link-container {
      position: fixed;
      bottom: 0;
      left: 0;
      width: calc(100vw - 40px);
      padding: 20px;
      background-color: #FFF;
      border: none;
      z-index: 3;
   }

   .content-container {
      padding-bottom: 150px
   }

   .table-wrapper-container {
      border: solid 1px #757575;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
   }

   table {
      margin-bottom: 15px;
      border-radius: 12px;
      border: none;
      width: 99%
   }

   table tr:nth-child(2) td {
      padding-top: 8px;
   }

   tr td:first-of-type {
      padding-left: 20px!important;
   }

   td {
      padding-bottom: 10px!important
   }

   th {
      border: none;
   }

   table tr:last-of-type td {
      padding-bottom: 30px!important
   }

   th label {
      padding: 20px;
      cursor: pointer;
   }

   .table-wrapper-container table:last-of-type {
      margin-bottom: 0;
   }

   .table-xs {
      display: none;
      height: 0;
      min-height: 0;
      max-height: 0;
      overflow: hidden;
      transition: 0.3s ease-in-out all;
      animation: accordion 0.3s ease-in-out forwards
   }

   @keyframes accordion {
      0% {opacity: 0; height: 0}
      100% {opacity: 1; height: fit-content}
   }

   .xs-info-box-trigger:checked + table {
      background-color: #f5f5f5;
   }

   .xs-info-box-trigger:checked + table .table-xs {
      height: fit-content;
      max-height: 1000px;
      display: table-row;
      overflow: visible
   }
}

@media only screen and (max-width: 450px) {
   td {
      display: block;
      width: calc(100% - 40px);
      padding: 5px 20px 5px 20px!important;
      margin: 0
   }

   tr td:first-of-type {
      font-weight: bold;
   }

   .address td:first-of-type {
      font-weight: normal!important
   }

   tr td:last-of-type {
      padding-bottom: 20px!important;
   }

   table tr:last-of-type td {
      padding-bottom: 0px!important;
  }

  table tr:last-of-type td:last-of-type {
     padding-bottom: 30px!important;
  }
}